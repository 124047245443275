<template>
  <tarjeta-datos header titulo="Autoestudios" icon="cog">
    <div class="pa-2">
      <div class="d-flex" style="gap: 8px">
        <v-btn
          v-if="estudio.autoestudio"
          @click.stop="descargarAutoestudio"
          color="primary"
          outlined
          large
        >
          <v-icon left>mdi-download</v-icon>DESCARGAR AUTOESTUDIO
        </v-btn>
        <v-btn
          :disabled="autoestudioMissing.length > 0"
          @click="hojaDeDatos"
          color="success"
          outlined
          large
          :loading="creatingAutoestudio"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>DESCARGAR HOJA DE DATOS
        </v-btn>
        <!-- <EstudioExpress :estudio="estudio" :idEstudio="estudio.idEstudio" @reload="$emit('reload')" /> -->
        <v-dialog max-width="800" v-model="opcionesAutoestudio.show">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" outlined large v-on="on" v-bind="attrs">
              <v-icon left>mdi-auto-fix</v-icon>AUTOESTUDIO EXPRESS
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dark dense color="primary">
              <v-toolbar-title>Estudio express</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.stop="opcionesAutoestudio.show = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <EstudioExpress
              :estudio="estudio"
              :idEstudio="estudio.idEstudio"
              @reload="$emit('reload')"
              ref="estudioExpress"
              :creatingAutoestudio.sync="creatingAutoestudio"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click.stop="$refs.estudioExpress.estudioExpress()"
                :loading="creatingAutoestudio"
              >
                <v-icon left>mdi-auto-fix</v-icon>
                Generar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        
        <v-dialog max-width="800" v-model="opcionesMovilidad.show" v-if="estudio.cargador" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" outlined large v-on="on" v-bind="attrs"  >
              <v-icon left>mdi-ev-station</v-icon>AUTOESTUDIO MOVILIDAD ELECTRICA
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dark dense color="primary">
              <v-toolbar-title>Estudio movilidad eléctrica</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.stop="opcionesMovilidad.show = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <EstudioMovilidad
              :estudio="estudio"
              :idEstudio="estudio.idEstudio"
              @reload="$emit('reload')"
              ref="estudioMovilidad"
              :creatingAutoestudio.sync="creatingMovilidad"
            />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click.stop="$refs.estudioMovilidad.estudioMovilidad()"
                :loading="creatingMovilidad"
              >
                <v-icon left>mdi-auto-fix</v-icon>
                Generar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>


      </div>
      <div
        v-if="autoestudioMissing.length > 0"
        class="d-flex mt-2"
        style="gap: 6px"
      >
        <v-alert
          v-for="(missing, index) in autoestudioMissing"
          :key="index"
          dense
          class="ma-0"
          type="error"
          text
          >Falta {{ missing.nombre }}</v-alert
        >
      </div>

      <v-simple-table class="mt-2 text-no-wrap">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Curva</th>
            <th>Estructura</th>
            <th>Potencia</th>
            <th>Precio</th>

            <th>CUPS Sim.</th>

            <th>% Autoconsumo</th>
            <th>% Autoconsumida</th>
            <th>% Excedentes</th>

            <th>€ Ahorro</th>

            <th>IVA</th>
            <th>Plantillas</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ex in estudio.autoestudios" :key="ex.idAutoestudio">
            <td>{{ parseDate(ex.fecha_creacion, false, true, true) }}</td>
            <td>{{ ex.curva }}</td>
            <td>{{ ex.estructura }}</td>
            <td>
              {{
                isNaN(ex.potencia_solicitada)
                  ? `[${ex.potencia_solicitada}]`
                  : ""
              }}
              {{ ex.potencia_instalacion }} kWp
            </td>
            <td>{{ ex.precio_instalacion }} €</td>
            <td>{{ ex.simulacion_vecinos }}</td>
            <td>{{ ex.pct_autoconsumo }} %</td>
            <td>{{ ex.pct_autoconsumida }} %</td>
            <td>{{ ex.pct_excedentes }} %</td>
            <td>{{ ex.ahorro_financiado }} €</td>
            <td>{{ ex.aplicar_iva ? "Si" : "No" }}</td>
            <td>{{ ex.plantilla }}</td>

            <td>
              <div class="d-flex align-center" style="gap: 6px">
                <!-- <v-btn fab x-small rounded color="secondary">
                    <v-icon >mdi-refresh</v-icon>
                  </v-btn> -->
                <v-btn
                  :loading="loading == ex.idAutoestudio"
                  fab
                  x-small
                  rounded
                  color="primary"
                  @click.stop="descargarDocumento(ex)"
                >
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
  </tarjeta-datos>
</template>

<script>
import { downloadFile, parseDate } from "@/utils/index.js";

export default {
  props: {
    estudio: Object,
    idEstudio: Number,
  },
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    EstudioExpress: () => import("./EstudioExpress.vue"),
    EstudioMovilidad: () => import("./EstudioMovilidad.vue"),
  },
  data() {
    return {
      showAutoestudio: false,
      autoestudio: {},
      opcionesAutoestudio: {
        curva: "residencial",
        paneles: null,
        precio_medio_kWh: null,
        show: false,
      },
      opcionesMovilidad: {
        show: false,
      },
      tiposCurva: [
        { nombre: "Residencial", value: "residencial", icon: "mdi-home" },
        {
          nombre: "Servicios Generales",
          value: "servicios_generales",
          icon: "mdi-office-building",
        },
      ],
      loading: true,
      creatingAutoestudio: false,
      creatingMovilidad: false,
    };
  },
  computed: {
    autoestudioMissing() {
      return [
        { nombre: "CUPS", value: this.estudio.cups },
        { nombre: "Inclinación", value: this.estudio.inclinacion },
        { nombre: "Azimut", value: this.estudio.azimut },
        { nombre: "Latitud", value: this.estudio.latitud },
        { nombre: "Longitud", value: this.estudio.longitud },
      ].filter((x) => x.value == null);
    },
  },
  methods: {
    parseDate,
    async hojaDeDatos() {
      // this.creatingAutoestudio = true;

      try {
        const { data } = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URL}/autoestudios/${this.idEstudio}`,
          data: {
            tipo: "hoja_de_datos",
          },
          responseType: "blob",
        });

        downloadFile(
          new File([data], `hoja_de_datos_${this.idEstudio}.xlsx`, {
            type: "application/excel",
          })
        );
      } catch (err) {
        this.$root.$emit("snack", "Ha ocurrido un error inesperado");
      }

      // this.creatingAutoestudio = false;
      this.$emit("reload");
    },

    async estudioExpress() {
      // this.creatingAutoestudio = true;

      try {
        const { data } = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URL}/autoestudios/${this.idEstudio}`,
          responseType: "blob",
          data: {
            tipo: "estudio_express",
            ...this.opcionesAutoestudio,
          },
        });

        downloadFile(
          new File([data], `estudio_express_${this.idEstudio}.pdf`, {
            type: "application/pdf",
          }),
          true
        );
      } catch (err) {
        this.$root.$emit("snack", "Ha ocurrido un error inesperado");
      }

      // this.creatingAutoestudio = false;
      this.$emit("reload");

      // this.modalDatos = true;
    },
    async descargarDocumento({ idAutoestudio, filename }) {
      this.loading = idAutoestudio;
      const { data } = await axios({
        method: "GET",
        responseType: "blob",
        url: `${process.env.VUE_APP_API_URL}/documentacion/${this.idEstudio}/${filename}`,
        params: { sub: "express" },
      });

      const file = new File([data], filename, {
        type: "application/pdf",
      });

      downloadFile(file, open);
      this.loading = null;
    },
    // async descargarAutoestudio() {

    //   const { data } = await axios({
    //     method: "GET",
    //     url: `${process.env.VUE_APP_API_URL}/autoestudio.php`,
    //     params: { idEstudio: this.estudio.idEstudio },
    //     responseType: "blob",
    //   });

    //   downloadFile(
    //     new File(
    //       [data],
    //       `AUTOESTUDIO_${this.estudio.nombreProyecto}_${Date.now()}.xlsx`,
    //       {
    //         type: "application/excel",
    //       }
    //     )
    //   );
    // },
  },
};
</script>

<style>
.item-selection {
  display: grid !important;
  grid-template-rows: 1fr 0fr;
  text-align: center;
}

.v-item--active {
  color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
}
</style>
